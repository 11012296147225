@import 'assets/colors.scss';

.login-page {
  text-align: center;
  width: 800px;
  margin: 85px auto 0;

  .page-title {
    color: $color-blue;
    font-size: 2em;
    margin: 31px 0 16px 0;
  }

  .page-desc {
    color: $color-grey-100;
  }

  .check {
    display: block;
    text-align: left;
  }

  .check,
  input[type='checkbox'] {
    margin: 10px 5px 0 0;
    cursor: pointer;
  }

  .login {
    display: block;
    text-align: center;
    justify-content: center;
    margin: 30px 0 0 0;
    width: 100%;

    .agreement {
      display: inline-block;
      text-align: left;
      margin: 20px 0 10px;
      font-size: 1.4rem;

      .text {
        width: 100%;
        height: 150px;
        margin: 10px 0 0 0;
        padding: 10px;
        overflow-y: scroll;
        border: 1px solid $color-blue-azure;
      }
    }

    .error {
      display: none;
    }

    .error-show {
      display: block;
      margin: 10px;
      color: darkred;
    }

    .button {
      display: inline-block;
      text-align: center;
    }
  }

  .outer-box {
    background: $color-blue;
    padding: 3px;
    margin: 1em 3em 1em 1em;
  }

  .inquiries {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .button {
      margin-left: 30px;
    }

    margin: 60px 0 30px 0;
  }
}
