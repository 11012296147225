@import 'assets/colors.scss';

$color-blue-main: #0b9dff;

.main-page {
  overflow: hidden;
  width: 100%;
  margin: 75px 0 40px 0;
  position: relative;
}

.presentation {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.presentation__slogan {
  color: $color-grey-600;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  font-size: 5vw;
  line-height: 1.2;
  letter-spacing: 0.5rem;
  margin-bottom: 20px;

  @media only screen and (min-width: 1280px) {
    font-size: 6.2rem;
  }

  span {
    color: $color-blue-main;
  }
}

.presentation__slides {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.presentation__slide {
  position: relative;
  opacity: 0;
  width: 100%;
  height: 500px;
  margin-bottom: 45px;
  text-align: center;
  flex: none;

  &--active {
    position: absolute;
    z-index: 2;
    left: 0;
    margin-left: auto;
    opacity: 100;
    width: 100%;
    transition: opacity 2s ease-out;
  }

  .image img {
    max-width: 100%;
  }

  .title {
    font-size: 4vw;
    color: $color-blue;
    font-weight: normal;
    margin: 40px 0 10px 0;

    @media only screen and (min-width: 1280px) {
      font-size: 3.9rem;
    }
  }

  .desc {
    color: $color-grey-100;
    margin: 0 0 2em 0;
    font-size: 1.5rem;

    p {
      margin: 0;
    }
  }

  .links {
    display: flex;
    justify-content: center;
    flex-flow: row;

    .link {
      margin: 0 10px;
      font-weight: bold;
      width: 190px;
      color: $color-blue;
      border-width: 1px;
    }

    .label {
      padding: 0.5em 1em 0.5em 1em;
    }
  }
}

.presentation__move {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  top: 50%;
  margin-top: -150px;
  border: 1px solid $color-blue-main;
  border-right: 0;
  border-top: 0;

  &:hover {
    cursor: pointer;
  }

  &.left {
    transform: rotate(45deg) translateZ(0);
    left: 30px;
  }

  &.right {
    right: 30px;
    transform: rotate(-135deg) translateZ(0);
  }
}

.presentation__indicator {
  display: block;
  text-align: center;
}

.webapp-version {
  font-size: 1.8rem;
  color: $color-grey-400;
  text-align: center;
  margin-top: 1em;
}
