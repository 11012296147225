.feature-page {
  display: flex;
  
  .contents {
    position: relative;
    margin: 60px 0 60px 40px;
    width: 1090px;
    min-height: 500px;
  }
}
