@import "assets/colors.scss";

.menu-area {
  display: flex;
  border-bottom: 1px solid $color-grey-cc;
  justify-content: space-between;

  .login {
    position: absolute;
    font-size: 1.4rem;
    text-align: right;
    top: -30px;
    right: 0;

    a {
      text-decoration: none;
      padding-bottom: 0.5em;
      color: $color-grey-400;
    }

    a:hover {
      color: $color-blue;
    }

    .name {
      font-weight: bold;
      color: $color-blue;
    }

    .logout {
      display: inline;
      margin-left: 20px;
      padding: 3px 10px 3px 10px;
      border: 1px solid $color-blue-azure;
      cursor: pointer;
    }
  }

  .logo {
    margin-left: 0; //for ie11 flex bug
    margin-right: auto; //for ie11 flex bug
    text-align: left;
    padding-bottom: 40px;
    margin-bottom: -4px;
    border-bottom: 4px solid $color-blue;

    img {
      width: 245px;
    }
  }

  .navigation {
    margin-right: 0; //for ie11 flex bug
    margin-left: auto; //for ie11 flex bug
    align-self: flex-end;
    font-size: 1.8rem;
    display: flex;
  }
}
