@import 'assets/colors.scss';

.aside-tip {
  &:before {
    content: '';
    position: absolute;
    top: -23px;
    right: 17px;
    width: 55px;
    height: 62px;
    background: url(https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/figure-aside.png) no-repeat;
    background-size: 100%;
  }

  position: absolute;
  bottom: 0;
  left: -290px;
  padding: 18px 20px 20px;
  width: 250px;
  box-sizing: border-box;
  background-color: #e0f2ff;

  .aside-tip-header {
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 6px 2px 0;
      width: 20px;
      height: 20px;
      background: url(https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/ico-exclamation.png) no-repeat;
      background-size: 100%;
    }

    margin: 0 0 11px;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-blue;
  }

  .aside-tip-body {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .aside-tip-linkto {
    display: block;
    padding: 11px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.05;
    color: #7f7f7f;
    background-color: #fff;
    text-decoration: none;
    border: 1px solid #b3b3b3;
  }
}
