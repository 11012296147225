//blue tone
$color-blue: #0081d8; //텍스트, 버튼 텍스트,
$color-blue-azure: #0091f3; //버튼테두리
$color-blue-picton: #32b4f1; //옅은 타이틀
$color-blue-time-table-cell: #04a3f6; //시간 설정 셀

//gray-text
$color-grey-100: #1a1a1a; //contents-title-sub-text
$color-grey-300: #313131; //
$color-grey-400: #4c4c4c; //login
$color-grey-4d: #4d4d4d;
$color-grey-600: #666666; //subnav, table contents , (#676767, #5d5d5d)
$color-grey-800: #8b8b8b; //table top
$color-grey-900: #9a9a9a; //nav
$color-grey-cc: #cccccc; //contents-nav c6c6c6
$color-grey-d9: #d9d9d9; // (#dcdcdc)
$color-grey-f2: #f2f2f2;
$color-grey-f7: #f7f7f7;

// Schedule grid
$color-default-border: #c8c8c8;
$color-selected-border: #0388cd;
$color-selected-background: #04a3f6;
$color-selecting-background: #47bcf8;
$color-selectable-box: gold;

// Red
$color-red: #ff0000