@import 'assets/colors.scss';

.indicator {
  display: flex;
  justify-content: center;

  .step {
    display: block;
    margin: 0 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $color-grey-cc;
  }

  .selected {
    background: $color-blue-picton;
  }
}
