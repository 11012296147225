@import 'assets/colors.scss';

.menu {
  margin: 0 50px -1px 0;
  font-size: 1.8rem;

  a {
    display: block;
    box-sizing: border-box;
    padding-bottom: 14px;

    text-decoration: none;
    font-weight: 700;
    color: $color-grey-900;
  }
  a:hover {
    padding-bottom: 10px;
    border-bottom: $color-blue 4px solid;
    color: $color-blue;
  }

  .selected {
    padding-bottom: 10px;
    border-bottom: $color-blue 4px solid;
    color: $color-blue !important;
  }
}


.menu:last-child {
  margin-right: 0;
}
