@import 'assets/colors.scss';

.border-box-button {
  cursor: pointer;
  text-align: center;
  width: 180px;
  padding: 20px 0;
  border: 1px solid $color-blue-azure;
  color: $color-blue;
  line-height: 1;
  font-weight: 700;

  &.medium {
    padding: 15px 0;
  }
}

.submit-button {
  background-color: white;
  font-family: "Noto Sans Kr", sans-serif;
  font-size: 1.6rem;
}

.disable {
  pointer-events: none;
  border-color: $color-grey-d9;
  color: $color-grey-d9;
}