html {
  font-size: 62.5%; /* ration for 10px */
  word-break: keep-all;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans Kr', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div.riotbar-footer-content.default-background {
}

#root {
  min-width: 1380px;
  max-width: 1380px;
  margin: 45px auto 0;
  position: relative;
  font-size: 1.6rem;
}

.riotbar-footer {
  width: 100%;
  min-width: 1280px;
  .riotbar-copyright {
    font-size: 1.2rem;
  }
}

body.riotbar-present {
  margin-top: 0 !important;
  overflow-x: auto;
}
.riotbar-header-wrapper {
  display: none;
}
