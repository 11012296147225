@import "assets/colors.scss";

.submenus {
  width: 250px;
  margin-top: 100px;
  text-align: left;
  line-height: 25px;

  .category {
    font-size: 2.2rem;
    padding-bottom: 10px;
    font-weight: 700;
    color: #333333;
    border-bottom: $color-blue 4px solid;
  }

  .menus {
    color: $color-blue;

    .submenu {
      cursor: pointer;
      display: block;
      width: 100%;
      color: $color-grey-600;
      padding: 15px 0;
      border-bottom: 1px solid $color-grey-cc;
      font-size: 1.8rem;
      line-height: 1;
      text-decoration: none;
      
      .marker {
        display: none;
      }
    }

    .submenu:hover {
      border-bottom: $color-blue 1px solid;
    }

    .selected {
      color: $color-blue;
      font-weight: bold;
      border-bottom: $color-blue 1px solid;

      .marker {
        display: inline-block;
        border: 2px solid $color-blue;
        border-left: 0;
        border-bottom: 0;
        transform: rotate(45deg);
        width: 7px;
        height:7px;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }
}
